import { createGlobalStyle } from "styled-components"

/* Experimental Styles */
export const ExperimentalGlobalStyle = createGlobalStyle`

.square-button-styles{
  border-radius: 12px !important;
  font-family: "sharp-grotesk-25";
}

.book-25-link-style{
  font-family: "sharp-grotesk-25";
}
`
/* Experimental Styles */
