import { applyFilters } from "./helpers"
import { Image, Dimensions } from "../types"

function buildImageUrl(originalPath: string, image: Image): string {
  const { width, height, quality, format, smartCrop } = image

  // add original path at the start
  let url = `${originalPath}/m`

  if (width && height) {
    url += `/${width}x${height}`
  }

  if (smartCrop) {
    url += `/smart`
  }

  let filters = [
    ...[quality > 0 ? `quality(${quality})` : ""],
    ...[format ? `format(${format})` : ""],
  ]

  // remove falsy elements
  filters = filters.filter((element) => Boolean(element) === true)

  if (filters.length > 0) {
    url += applyFilters(filters)
  }

  return url
}

export function buildLowFiUrl(
  originalPath: string,
  dimensions: Dimensions
): string {
  return buildImageUrl(originalPath, {
    width: (dimensions.width / 3).toFixed(0),
    height: (dimensions.height / 3).toFixed(0),
    format: "webp",
    smartCrop: true,
    quality: 10,
    toFormat: "webP",
  })
}

export default buildImageUrl
