import { DefaultOptions } from "./types"

// These values are used to create an array of widths depending on the layout and width passed to the plugin
export const sizeMultipliersFullwidth = [0.5, 0.65, 0.75, 1]
export const sizeMultipliersConstrained = [0.5, 0.75, 0.85, 2]

/* These breakpoints take the DPR of devices into account when creating the srcset
see the bottom of https://www.mydevice.io/#compare-devices for common dpr ratings */
export const srcSetBreakpoints = [640, 768, 1024, 1366, 1600]

/* Example of how the above two arrays work together:
Image with dimensions created with sizeMultipliersConstrained[0]/sizeMultipliersFullWidth[0] will be served for breakpoint srcSetBreakpoints[0]
NOTE: If the sizeMultipliers array has less values than srcSetBreakpoints, then the last value in the sizeMultipliers array
will be served at all screen sizes starting at the corresponding srcSetBreakpoint value */

export const defaultOptions: DefaultOptions = {
  quality: 80,
  format: null,
  toFormat: null,
  smartCrop: true,
}
