import type tailwindConfig from "../../../../tailwind.config"

const theme =
  // Static tailwind config export using "babel-plugin-preval"
  // Note use of CommonJS here is for preval to work
  // @ts-ignore
  preval`
const resolveConfig = require("tailwindcss/resolveConfig")

const tailwindConfig = require("../../../../tailwind.config")

const {
  theme: { screens, colors },
} = resolveConfig(tailwindConfig)

module.exports = { screens, colors }
`

type YocoComTailwindTheme = typeof tailwindConfig.theme

/**
 * Values derived from our tailwind config,
 * stripped down to only those
 * we use to lower bundle size.
 *
 * import this from from '@theme' to prevent Storybook from breaking
 **/
export default theme as Pick<YocoComTailwindTheme, "screens" | "colors">
