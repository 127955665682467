export enum Color {
  Green = "green",
  MintGreen = "mint-green",
  Orange = "orange",
  Yellow = "yellow",
  Blue = "blue",
  LightBlue = "light-blue",
  Black = "black",
  Charcoal = "charcoal",
  LightGray = "light-gray",
  Cream = "cream",
  Pink = "pink",
  White = "white",
}
