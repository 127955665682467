import loadable from "@loadable/component"
import React from "react"

const ArticleSection = loadable(
  () => import("../components/StoryBlok/V2/Layout/ArticleSection")
)

// Hero components start
const ContentHalfHero = loadable(
  () => import("../components/StoryBlok/V2/Hero/Content/HalfHero")
)

const ContentCentreHero = loadable(
  () => import("../components/StoryBlok/V2/Hero/Content/CentreHero")
)

const ContentFloodHero = loadable(
  () => import("../components/StoryBlok/V2/Hero/Content/FloodHero")
)

const ProductHalfHero = loadable(
  () => import("../components/StoryBlok/V2/Hero/Product/HalfHero")
)

const ProductThirdsHero = loadable(
  () => import("../components/StoryBlok/V2/Hero/Product/ThirdsHero")
)

const ArticleHero = loadable(
  () => import("../components/StoryBlok/V2/Hero/ArticleHero")
)

// Hero components end

// Layout components start
const GridSection = loadable(
  () => import("../components/StoryBlok/V2/Layout/GridSection")
)
const MasonrySection = loadable(
  () => import("../components/StoryBlok/V2/Layout/MasonrySection")
)
const SliderSection = loadable(
  () => import("../components/StoryBlok/V2/Layout/SliderSection")
)
const ComparisonTableSection = loadable(
  () => import("../components/StoryBlok/V2/Layout/ComparisonTableSection")
)
const SpacerSection = loadable(
  () => import("../components/StoryBlok/V2/Layout/SpacerSection")
)
const SocialProofing = loadable(
  () => import("../components/StoryBlok/V2/Section/SocialProofing")
)

const ProductInfoSection = loadable(
  () => import("../components/StoryBlok/V2/Section/ProductInfo")
)

// Layout components end

// Social Proofing components start

const TestimonialCard = loadable(
  () => import("../components/StoryBlok/V2/Card/TestimonialCard")
)

// Social Proofing components end

// Banner start
const PaymentLogoBanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/PaymentLogoBanner")
)
const CentreCTABanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/CentreCTABanner")
)
const PromoCTACounterBanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/PromoCTACounterBanner")
)
const RatingBanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/RatingBanner")
)
const TabSelectorBanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/TabSelectorBanner")
)
const SocialProofBanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/SocialProofBanner")
)
const ScrollingLogoBanner = loadable(
  () => import("../components/StoryBlok/V2/Banner/ScrollingLogoBanner")
)
const InteractiveImage = loadable(
  () => import("../components/StoryBlok/V2/Banner/InteractiveImage")
)
// Banner end

// Card/Content components start
const BenefitBulletCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/Benefit/Bullet")
)
const BenefitCopyCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/Benefit/Copy")
)
const BulletPointBulletItemsCard = loadable(
  () =>
    import("../components/StoryBlok/V2/Card/Content/BulletPoints/BulletItems")
)
const BulletPointIconInfoCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/BulletPoints/IconInfo")
)
const DataCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/Data")
)
const InfoCTACard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/Info/CTA")
)
const InfoRatingCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/Info/Rating")
)
const InfoTextCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/Info/Text")
)
const BlogCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Content/BlogCard")
)
// Card/Content components end

// Card/Package components start
const PackageBulletCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Package/BulletCard")
)
const PackageDataCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Package/DataCard")
)
const CompareCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Package/CompareCard")
)
const TurnoverSelectorCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Package/TurnoverSelectorCard")
)
const MachineBenefitCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Package/MachineBenefitCard")
)
const MachineBundleCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Package/MachineBundleCard")
)
// Card/Package components end

// Card/Eco-System components start
const EcoSystemContentCard = loadable(
  () => import("../components/StoryBlok/V2/Card/EcoSystem/Content")
)
const EcoSystemBenefitsCard = loadable(
  () => import("../components/StoryBlok/V2/Card/EcoSystem/Benefits")
)
const EcoSystemIntroCard = loadable(
  () => import("../components/StoryBlok/V2/Card/EcoSystem/Intro")
)
// Card/Eco-System components end

// Card/Fees components start
const FeesCalculatorRecommendedCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Fees/RecommendedSlider")
)
const FeesCalculateCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Fees/CalculateCard")
)
// Card/Fees components end

// Section components start
const FaqSection = loadable(
  () => import("../components/StoryBlok/V2/Section/Faq")
)
const ModalSection = loadable(
  () => import("../components/StoryBlok/V2/Section/ModalSection")
)
const ContentBanner = loadable(
  () => import("../components/StoryBlok/V2/Section/Banner/Content")
)
const RichMediaBanner = loadable(
  () => import("../components/StoryBlok/V2/Section/Banner/RichMedia")
)
const CTADriverBanner = loadable(
  () => import("../components/StoryBlok/V2/Section/Banner/CTADriver")
)
// Section components end

// Form components start
const SalesForm = loadable(
  () => import("../components/StoryBlok/V2/Form/SalesForm")
)

const RentalReaderForm = loadable(
  () => import("../components/StoryBlok/V2/Form/RentalReaderForm")
)
//  Form components end

// Card/Product components start
const CardMachineCard = loadable(
  () => import("../components/StoryBlok/V2/Card/Product/CardMachine")
)
// Card/Product components end

// Card/RichMedia components start
const RichMediaHightlightCard = loadable(
  () => import("../components/StoryBlok/V2/Card/RichMedia/Highlight")
)
const RichMediaInfoTabCard = loadable(
  () => import("../components/StoryBlok/V2/Card/RichMedia/InfoTab")
)
const RichMediaUIOverlayCard = loadable(
  () => import("../components/StoryBlok/V2/Card/RichMedia/UIOverlay")
)
// Card/RichMedia components end

const QuestionAnswerBlock = loadable(
  () => import("../components/StoryBlok/V2/Section/QuestionAnswer")
)

const QuizQuestions = loadable(
  () => import("../components/StoryBlok/V2/Quiz/QuizQuestions"),
  {
    fallback: <div className="min-h-screen bg-charcoal-v2"></div>,
  }
)

// V2/Legacy components start
const RichTextBlock = loadable(
  () => import("../components/StoryBlok/RichTextBlock")
)
const ModalContainer = loadable(
  () => import("../components/StoryBlok/V2/Legacy/ModalContainer")
)
const EmbedCode = loadable(
  () => import("../components/StoryBlok/V2/Legacy/EmbedCode")
)

const ImageGenerator = loadable(
  () => import("../components/StoryBlok/V2/Legacy/ImageGenerator")
)
// V2/Legacy components end

const Kiosk = loadable(() => import("../components/StoryBlok/Lab/Kiosk"))

// V2/Lab components start
const CalendlyWidget = loadable(
  () => import("../components/StoryBlok/Lab/CalendlyWidget")
)

const InteractiveParagraph = loadable(
  () => import("../components/StoryBlok/V2/Lab/InteractiveParagraph")
)
const BenefitDriver = loadable(
  () => import("../components/StoryBlok/V2/Lab/BenefitDriver")
)
const CategorySlider = loadable(
  () => import("../components/StoryBlok/V2/Lab/CategorySlider")
)
const CategorySlide = loadable(
  () => import("../components/StoryBlok/V2/Lab/CategorySlide")
)
const CartOverview = loadable(
  () => import("../components/StoryBlok/V2/Lab/CartOverview")
)
// V2/Lab components end

const TextItem = loadable(
  () => import("../components/StoryBlok/V2/Shop/TextItem")
)

const IconInfo = loadable(
  () => import("../components/StoryBlok/V2/Shop/IconItem")
)

const ImageItem = loadable(
  () => import("../components/StoryBlok/V2/Shop/ImageItem")
)

const ArticleImage = loadable(
  () => import("../components/StoryBlok/V2/ArticleImage")
)

const StoryblokAccordion = loadable(
  () => import("../components/StoryBlok/Accordion")
)

export default {
  contentHalfHero: ContentHalfHero,
  contentCentreHero: ContentCentreHero,
  contentFloodHero: ContentFloodHero,
  productHalfHero: ProductHalfHero,
  productThirdsHero: ProductThirdsHero,
  articleHero: ArticleHero,
  gridSection: GridSection,
  masonrySection: MasonrySection,
  sliderSection: SliderSection,
  comparisonTableSection: ComparisonTableSection,
  spacerSection: SpacerSection,
  productInfoSection: ProductInfoSection,
  socialProofing: SocialProofing,
  testimonialCard: TestimonialCard,
  benefitCopyCard: BenefitCopyCard,
  benefitBulletCard: BenefitBulletCard,
  dataCard: DataCard,
  infoCTACard: InfoCTACard,
  infoRatingCard: InfoRatingCard,
  infoTextCard: InfoTextCard,
  blogCard: BlogCard,
  bulletPointBulletItemsCard: BulletPointBulletItemsCard,
  bulletPointIconInfoCard: BulletPointIconInfoCard,
  ecoSystemContentCard: EcoSystemContentCard,
  ecoSystemBenefitsCard: EcoSystemBenefitsCard,
  ecoSystemIntroCard: EcoSystemIntroCard,
  faqSection: FaqSection,
  modalSection: ModalSection,
  cardMachineCard: CardMachineCard,
  richMediaHighlightCard: RichMediaHightlightCard,
  richMediaInfoTabCard: RichMediaInfoTabCard,
  richMediaUIOverlayCard: RichMediaUIOverlayCard,
  paymentLogoBanner: PaymentLogoBanner,
  centreCTABanner: CentreCTABanner,
  promoCTACounterBanner: PromoCTACounterBanner,
  ratingBanner: RatingBanner,
  tabSelectorBanner: TabSelectorBanner,
  contentBanner: ContentBanner,
  socialProofBanner: SocialProofBanner,
  scrollingLogoBanner: ScrollingLogoBanner,
  packageBulletCard: PackageBulletCard,
  packageDataCard: PackageDataCard,
  compareCard: CompareCard,
  packageTurnoverSelectorCard: TurnoverSelectorCard,
  packageMachineBenefitCard: MachineBenefitCard,
  packageMachineBundleCard: MachineBundleCard,
  richMediaBanner: RichMediaBanner,
  ctaDriverBanner: CTADriverBanner,
  question_answer: QuestionAnswerBlock,
  quizQuestions: QuizQuestions,
  sales_form: SalesForm, // TODO: Rename on storyblok
  richtext_block: RichTextBlock, // TODO: Move out of storyblok V2/Legacy group when reworked
  videoModalContainer: ModalContainer, // TODO: Move out of storyblok V2/Legacy group when reworked
  embed_code: EmbedCode, // TODO: Move out of storyblok V2/Legacy group when reworked
  feesCalculatorRecommendedCard: FeesCalculatorRecommendedCard,
  feesCalculateCard: FeesCalculateCard,
  calendly_widget: CalendlyWidget,
  image_generator: ImageGenerator,
  kiosk: Kiosk,
  interactiveParagraph: InteractiveParagraph,
  interactiveImage: InteractiveImage,
  benefitDriver: BenefitDriver,
  categorySlider: CategorySlider,
  categorySlide: CategorySlide,
  cartOverview: CartOverview,
  textItem: TextItem,
  iconInfo: IconInfo,
  imageItem: ImageItem,
  articleImage: ArticleImage,
  rentalReaderForm: RentalReaderForm,
  accordion: StoryblokAccordion,
  articleSection: ArticleSection,
}
