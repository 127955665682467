import classNames from "classnames"
import React from "react"
import type { PropsWithChildren } from "react"

type Props = {
  className?: string
}

const Grid = ({ children, className, ...props }: PropsWithChildren<Props>) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-4 md-v2:grid-cols-6 lg-v2:grid-cols-12 gap-grid-xs-v2 sm-v2:gap-grid-sm-v2 md-v2:gap-grid-md-v2 lg-v2:gap-grid-lg-v2 xl-v2:gap-grid-xl-v2 items-stretch",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export default Grid
