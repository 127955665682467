// MARK: used in v2
import { Cart } from "@yoco/shop-sdk/lib/types"
import React, { createContext, useCallback, useMemo, useState } from "react"

const emptyFn = (): void | Promise<void> => {
  console.warn(`StoreContext not ready!`)
}

export enum CheckoutStep {
  Contact = 0,
  Shipping,
  Payment,
  Confirm,
}

export const INITIAL_VALUES: YocoCom.CheckoutProvider = {
  step: CheckoutStep.Contact,
  orderNumber: undefined,
  setStep: emptyFn,
  setRemoteCart: emptyFn,
  setContactInfo: emptyFn,
  setAddress: emptyFn,
  setPaidWithMethod: emptyFn,
  setOrderNumber: emptyFn,
  resetCheckout: emptyFn,
}

export const useCheckoutState = (): YocoCom.CheckoutProvider => {
  const [step, setStep] = useState<number>(INITIAL_VALUES.step)
  const [remoteCart, setRemoteCart] = useState<Cart>()
  const [contactInfo, setContactInfo] = useState<YocoCom.ContactInfo>()
  const [address, setAddress] = useState<YocoCom.ShippingAddress>()
  const [paidWithMethod, setPaidWithMethod] = useState<YocoCom.PaymentMethod>()
  const [orderNumber, setOrderNumber] = useState<string>()

  const resetCheckout = useCallback(async () => {
    setStep(INITIAL_VALUES.step)
    setRemoteCart(undefined)
    setContactInfo(undefined)
    setAddress(undefined)
    setPaidWithMethod(undefined)
    setOrderNumber(undefined)
  }, [])

  return {
    step,
    setStep,
    remoteCart,
    setRemoteCart,
    contactInfo,
    setContactInfo,
    address,
    setAddress,
    paidWithMethod,
    setPaidWithMethod,
    orderNumber,
    setOrderNumber,
    resetCheckout,
  }
}

export const CheckoutContext = createContext(INITIAL_VALUES)

type StoreProviderProps = {
  children: React.ReactNode
}

const CheckoutProvider = ({ children }: StoreProviderProps) => {
  const { ...checkoutState } = useCheckoutState()

  const values = useMemo(
    () => ({
      ...checkoutState,
    }),
    [checkoutState]
  )

  return (
    <CheckoutContext.Provider value={values}>
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutProvider
