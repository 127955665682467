import React from "react"

import {
  ToastNotificationsBaseProps,
  ToastNotificationsCountDownProps,
} from "."
import CountdownTimer from "../../../elements/V2/CountdownTimer"
import Typography from "../../../elements/V2/Typography"

import PillButton from "@components/elements/V2/Buttons/PillButton"
import { getAccentColor } from "@utils/V2/color"

type CountDownBannerProps = Omit<
  ToastNotificationsBaseProps & ToastNotificationsCountDownProps,
  "component"
>

const CountDownBanner = ({
  text,
  ctaText,
  ctaLink,
  ctaColor,
  textColor,
  timerLabel,
  ctaTextColor,
  timerTextColor,
  timerTargetDate,
  backgroundColor,
}: CountDownBannerProps) => {
  textColor = textColor || getAccentColor(backgroundColor)

  return (
    <>
      <CountdownTimer
        label={timerLabel}
        targetDate={timerTargetDate}
        textColor={timerTextColor || getAccentColor(backgroundColor)}
      />
      <div className="flex items-center gap-[13px]">
        {text && (
          <Typography
            text={text}
            font="grotesk"
            weight="medium"
            color={textColor}
            size="lead-md-fixed"
            className="hidden md-v2:block"
          />
        )}
        <PillButton
          size="small"
          style="solid"
          text={ctaText}
          linkUrl={ctaLink}
          className="h-[30px] px-[14px]"
          overrideTextColor={ctaTextColor || backgroundColor}
          color={ctaColor || getAccentColor(backgroundColor)}
        />
      </div>
    </>
  )
}

export default CountDownBanner
