import { useStaticQuery, graphql } from "gatsby"
import { useMemo, useCallback } from "react"

import { DEFAULT_STOREFRONT } from "../cart/constants"

import { getBundlePrices } from "@utils/shop"

export const useGetBundles = (): {
  allBundle: { edges: { node: YocoCom.SdkBundle }[] }
} => {
  const bundles = useStaticQuery(graphql`
    query Bundles {
      allBundle {
        edges {
          node {
            id
            externalId
            bundle_code
            name
            short_name
            image
            unit_price_formatted
            unit_price_currency
            unit_price
            original_unit_price
            is_discounted
            type
            is_listed_in_catalogue
            max_quantity_per_cart
            tax_policy
            tax_rate
            ribbon_text
            ribbon_class
            storefronts
            reason_not_available
          }
        }
      }
    }
  `)

  return bundles
}

export const useGetBundleByCode = (): ((
  code: string,
  storefront?: string
) => YocoCom.SdkBundle | null) => {
  const bundles = useGetBundles()

  const getBundleByCode = useCallback(
    (code: string, storefront = DEFAULT_STOREFRONT) => {
      const bundle = bundles.allBundle.edges.find((bundle) => {
        return (
          bundle.node.bundle_code === code &&
          bundle.node.is_listed_in_catalogue &&
          bundle.node.storefronts.includes(storefront)
        )
      })

      return bundle ? bundle.node : null
    },
    [bundles]
  )

  return getBundleByCode
}

type GetBundlePriceParams = {
  bundleCode: string
  storyblokProductPrice: string
  forceStoryblokPrice: boolean
  storyblokPromotionPrice?: string
}

export const useGetBundlePrice = ({
  bundleCode,
  storyblokProductPrice,
  forceStoryblokPrice,
  storyblokPromotionPrice,
}: GetBundlePriceParams) => {
  const getSdkBundle = useGetBundleByCode()

  const sdkBundle = getSdkBundle(bundleCode)

  const bundlePrices = useMemo(
    () =>
      getBundlePrices(
        sdkBundle,
        storyblokProductPrice,
        forceStoryblokPrice,
        storyblokPromotionPrice
      ),
    [
      forceStoryblokPrice,
      sdkBundle,
      storyblokProductPrice,
      storyblokPromotionPrice,
    ]
  )

  return bundlePrices
}
