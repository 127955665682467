import React, { useContext } from "react"

import { CartContext } from "../store"

type Props = {
  numberOfItems?: number
}

export default function CartWidget({ numberOfItems = 0 }: Props) {
  const cartContext = useContext(CartContext)

  const toggleCartPreview = () => {
    if (!cartContext.cartDisabled) {
      cartContext.setCartPreviewOpen(!cartContext.cartPreviewOpen)
    }
  }

  return (
    <button
      type="button"
      className="flex items-center rounded-32-v2 bg-blue-v2 px-16-v2 py-12-v2 text-body-md-v2 font-medium !leading-none text-white-v2 md-v2:px-[18px] md-v2:py-[14px] lg-v2:px-20-v2 lg-v2:py-16-v2"
      onClick={toggleCartPreview}
    >
      {/* // TODO: Use CartIcon instead of image */}
      <img
        src="/icons/cart/cart-icon.svg"
        alt="Cart icon"
        width={16}
        height={16}
        className="mr-[5px]"
      />{" "}
      <span className="ml-4-v2 hidden lg-v2:inline">Cart</span>
      <span className="-my-4-v2 ml-4-v2 rounded-24-v2 bg-white-v2 px-12-v2 py-4-v2 text-black-v2 lg-v2:ml-8-v2">
        {numberOfItems}
      </span>
    </button>
  )
}
