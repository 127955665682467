import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  color: Color
  mobileMenuOpen: boolean
  onToggle: (isActive: boolean) => void
}

const HamburgerIcon = ({ color, mobileMenuOpen, onToggle }: Props) => {
  function handleToggle() {
    if (onToggle) {
      onToggle(!mobileMenuOpen)
    }
  }

  return (
    <button
      className="block"
      type="button"
      aria-label="Menu"
      onClick={handleToggle}
    >
      <span
        className={classNames(
          "mb-[3.25px] block h-[2.5px] w-5 origin-top-left transition-all duration-200",
          getBackgroundColorClass(color),
          { "rotate-45": mobileMenuOpen }
        )}
      ></span>
      <span
        className={classNames(
          "mb-[3.25px] block h-[2.5px] w-5 transition-all duration-200",
          getBackgroundColorClass(color),
          { "-translate-x-full opacity-0": mobileMenuOpen }
        )}
      ></span>
      <span
        className={classNames(
          "mb-[3.25px] block h-[2.5px] w-5 origin-bottom-left transition-all duration-200",
          getBackgroundColorClass(color),
          { "-rotate-45": mobileMenuOpen }
        )}
      ></span>
    </button>
  )
}
export default HamburgerIcon
