import classNames from "classnames"
import React, { useEffect, useState } from "react"

import Typography from "./Typography"
import { Color } from "../../../constants/V2/color"

type Time = {
  value: number
  key: string
}

type Props = {
  label: string
  targetDate: string
  textColor: Color
}

const CountdownTimer = ({ label, targetDate, textColor }: Props) => {
  const [times, setTimes] = useState<Time[]>()

  useEffect(() => {
    const endDate = new Date(targetDate)

    const interval = setInterval(() => {
      if (endDate) {
        const today = new Date().getTime()
        const timeRemaining = (endDate.getTime() - today) / 1000
        setTimes([
          {
            value:
              timeRemaining < 0
                ? 0
                : Math.floor(timeRemaining / (60 * 60 * 24)),
            key: "DAYS",
          },
          {
            value:
              timeRemaining < 0
                ? 0
                : Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60)),
            key: "HOURS",
          },
          {
            value:
              timeRemaining < 0
                ? 0
                : Math.floor((timeRemaining % (60 * 60)) / 60),
            key: "MINS",
          },
          {
            value: timeRemaining < 0 ? 0 : Math.floor(timeRemaining % 60),
            key: "SECS",
          },
        ])

        if (timeRemaining < 0) {
          clearInterval(interval)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [targetDate])

  return (
    <div className="flex flex-col">
      <Typography
        font="grotesk"
        weight="book"
        size="body-sm-fixed"
        color={textColor}
        text={label}
      />
      <div
        className={classNames(
          "-mt-4-v2 flex h-16-v2 w-88-v2 gap-[6px] transition-opacity duration-300 md-v2:h-[21px] md-v2:w-104-v2 lg-v2:w-[114px]",
          times?.length ? "opacity-100" : "opacity-0"
        )}
      >
        {times?.map((time, i) => (
          <Typography
            font="grotesk"
            weight="medium"
            size="body-lg"
            color={textColor}
            key={`${time.key}_${i}`}
            text={`${time.value}`.padStart(2, "0")}
          />
        ))}
      </div>
    </div>
  )
}

export default CountdownTimer
