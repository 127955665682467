import { useStaticQuery, graphql } from "gatsby"
import { useState, useEffect, useMemo, useCallback } from "react"

import { DEFAULT_STOREFRONT } from "../cart/constants"
import { parseStoryblokDate } from "../utils/storyblok"

import { getProductPrices } from "@utils/shop"

export interface UsePromotion {
  renderPromotion: boolean
  startDate: Date | null
  endDate: Date | null
}

export const usePromotion = (
  promotionStartDate?: string,
  promotionEndDate?: string
): UsePromotion => {
  const [renderPromotion, setRenderPromotion] = useState(false)
  const startDate = useParseStoryBlokDate(promotionStartDate)
  const endDate = useParseStoryBlokDate(promotionEndDate)

  useEffect(() => {
    const today = new Date()
    const shouldRenderPromotion =
      startDate && endDate && today >= startDate && today <= endDate

    setRenderPromotion(shouldRenderPromotion || false)
  }, [startDate, endDate])

  return {
    renderPromotion,
    startDate,
    endDate,
  }
}

export const useParseStoryBlokDate = (date?: string): Date | null => {
  const parsedDate = useMemo(() => parseStoryblokDate(date), [date])

  return parsedDate
}

export const useGetProducts = (): {
  allProduct: { edges: { node: YocoCom.SdkProduct }[] }
} => {
  const products = useStaticQuery(graphql`
    query Products {
      allProduct {
        edges {
          node {
            id
            externalId
            sku
            name
            short_name
            image
            unit_price_formatted
            unit_price_currency
            unit_price
            original_unit_price
            is_discounted
            is_available_on_payment_terms
            type
            is_listed_in_catalogue
            max_quantity_per_cart
            tax_policy
            tax_rate
            ribbon_text
            ribbon_class
            third_party_reviews {
              description
              name
              rating
              url
            }
            storefronts
            reason_not_available
          }
        }
      }
    }
  `)

  return products
}

// MARK: Used in V2
export const useGetProductBySku = (): ((
  sku: string,
  storefront?: string
) => YocoCom.SdkProduct | null) => {
  const products = useGetProducts()

  const getProductBySku = useCallback(
    (sku: string, storefront = DEFAULT_STOREFRONT) => {
      const product = products.allProduct.edges.find((product) => {
        return (
          product.node.sku === sku &&
          product.node.is_listed_in_catalogue &&
          product.node.storefronts.includes(storefront)
        )
      })

      return product ? product.node : null
    },
    [products]
  )

  return getProductBySku
}

type GetProductPriceParams = {
  productSku: string
  storyblokProductPrice: string
  forceStoryblokPrice: boolean
  storyblokPromotionPrice?: string
}

export const useGetProductPrice = ({
  productSku,
  storyblokProductPrice,
  forceStoryblokPrice,
  storyblokPromotionPrice,
}: GetProductPriceParams) => {
  const getSdkProduct = useGetProductBySku()

  const sdkProduct = getSdkProduct(productSku)

  const productPrices = useMemo(
    () =>
      getProductPrices(
        sdkProduct,
        storyblokProductPrice,
        forceStoryblokPrice,
        storyblokPromotionPrice
      ),
    [
      forceStoryblokPrice,
      sdkProduct,
      storyblokProductPrice,
      storyblokPromotionPrice,
    ]
  )

  return productPrices
}
