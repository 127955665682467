// MARK: Used in V2
import { useLocation } from "@reach/router"
import React, { createContext, useEffect, useMemo } from "react"

import { useFetchConsolidatedCartProducts } from "./hooks"
import { CartReducer, INITIAL_VALUES } from "./reducer"
import {
  fetchCartId,
  storeCartPromoCode,
  localStorageAvailable,
} from "../utils/shop"

export const CartContext = createContext(INITIAL_VALUES)

type StoreProviderProps = {
  children: React.ReactNode
}

const CartProvider = ({ children }: StoreProviderProps) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const promotionParam = urlParams.get("promotion")
  const { cart, ...cartActions } = CartReducer()
  const fetchProducts = useFetchConsolidatedCartProducts()

  useEffect(() => {
    if (localStorageAvailable()) {
      cartActions.setCartId(fetchCartId())
      cartActions.setProducts(fetchProducts() ?? [])

      if (promotionParam && typeof promotionParam === "string") {
        storeCartPromoCode(promotionParam)
      }
    }
    cartActions.setCartInitalised()
  }, [])

  const values = useMemo(
    () =>
      localStorageAvailable()
        ? {
            ...cart,
            ...cartActions,
            cartDisabled: false,
          }
        : {
            ...INITIAL_VALUES,
            cartDisabled: true,
          },
    [cart, cartActions]
  )

  return <CartContext.Provider value={values}>{children}</CartContext.Provider>
}

export default CartProvider
