import { useStaticQuery, graphql } from "gatsby"
import { useCallback } from "react"

interface storyBlokBundle {
  uuid: string
  content: string
  full_slug: string
}

export const useGetStoryblokBundle = (): ((
  id: string,
  useSlug?: boolean
) => Storyblok.BundleConfig | null) => {
  const data = useStaticQuery(graphql`
    query StoryblokBundles {
      allStoryblokEntry(
        filter: { full_slug: { regex: "/settings/bundles/" } }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `)

  return useCallback(
    (id: string, useSlug = false) => {
      const bundle = data.allStoryblokEntry.edges.find(
        (bundle: { node: storyBlokBundle }) => {
          if (useSlug) {
            return bundle.node.full_slug === id
          } else {
            return bundle.node.uuid === id
          }
        }
      )

      if (bundle) {
        return JSON.parse(bundle.node.content)
      }

      return null
    },
    [data]
  )
}
