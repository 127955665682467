import { useLocation } from "@reach/router"
import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState } from "react"

import ErrorIcon from "../../static/icons/alert/icon-alert-danger.svg"
import InfoIcon from "../../static/icons/alert/icon-alert-info.svg"
import SuccessIcon from "../../static/icons/alert/icon-alert-success.svg"
import WarningIcon from "../../static/icons/alert/icon-alert-warning.svg"

const NOTIFICATION_LIFETIME = 2000

const autoHideDuration = {
  success: 2000,
  warning: 4000,
  error: 6000,
  info: 3000,
}

interface Props {
  type: string
  message?: string
  title: string
  onClose?: () => void
}

const Toast = ({ type, message, title, onClose }: Props) => {
  const [hide, setHide] = useState(false)
  const { pathname } = useLocation()
  const locationWithError = useRef(pathname)

  const backgroundColor =
    type === "success"
      ? "bg-toast-success"
      : type === "danger"
        ? "bg-toast-danger"
        : type === "warning"
          ? "bg-toast-warning"
          : "bg-toast-info"

  const toastIconPath =
    type === "success"
      ? SuccessIcon
      : type === "danger"
        ? ErrorIcon
        : type === "warning"
          ? WarningIcon
          : InfoIcon

  const visibilityTimeout =
    type === "success"
      ? autoHideDuration.success
      : type === "danger"
        ? autoHideDuration.error
        : type === "warning"
          ? autoHideDuration.warning
          : autoHideDuration.info

  const notificationRef = useRef<HTMLDivElement>(null)

  const disappearTimerRef = useRef<number | undefined>()

  const disappear = useCallback((timestamp: number) => {
    if (!disappearTimerRef.current) {
      disappearTimerRef.current = timestamp
    }
    if (timestamp - disappearTimerRef.current >= NOTIFICATION_LIFETIME) {
      disappearTimerRef.current = undefined
      if (
        (notificationRef.current?.style.opacity == "0" ||
          notificationRef.current?.style.opacity) == "" &&
        onClose
      ) {
        onClose()
      }
    } else {
      requestAnimationFrame(disappear)
    }
  }, [])

  const timeoutTimerRef = useRef<number | undefined>()

  const timeout = useCallback(
    (timestamp: number) => {
      if (!timeoutTimerRef.current) {
        timeoutTimerRef.current = timestamp
      }
      if (
        timestamp - timeoutTimerRef.current >= visibilityTimeout &&
        timeoutTimerRef !== undefined
      ) {
        timeoutTimerRef.current = undefined
        disappearTimerRef.current = undefined

        setHide(true)
        requestAnimationFrame(disappear)
      } else {
        requestAnimationFrame(timeout)
      }
    },
    [disappear, visibilityTimeout]
  )

  const reset = useCallback(() => {
    timeoutTimerRef.current = undefined
    disappearTimerRef.current = undefined
    setHide(false)
  }, [])

  useEffect(() => {
    if (locationWithError.current !== pathname) {
      setHide(true)
    } else {
      reset()
      requestAnimationFrame(timeout)
    }

    return () => {
      setHide(false)
    }
  }, [type, reset, timeout, pathname])

  return (
    <div
      ref={notificationRef}
      className={classNames(
        "fixed bottom-0 left-0 z-50 w-full px-16-v2 py-8-v2",
        backgroundColor,
        { "duration-2000 opacity-0 transition-opacity": hide }
      )}
    >
      <div className="flex items-center justify-center gap-16-v2">
        <div
          className="flex cursor-pointer items-center gap-12-v2"
          onClick={onClose}
        >
          <img src={toastIconPath} alt="toast icon" className="h-32-v2" />
          <h6 className="text-fs-14 leading-[18px] text-white">
            {title}
            {message && message !== "" ? `, ${message}` : null}
          </h6>
        </div>
        <button
          className="cursor-pointer text-fs-12 font-medium text-white"
          onClick={onClose}
        >
          HIDE
        </button>
      </div>
    </div>
  )
}

export default Toast
