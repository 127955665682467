// MARK: used in v2
import { useReducer, useCallback } from "react"

import { WINDOWS_SCROLLBAR_WIDTH } from "../constants/spacing"

type CartActions = {
  payload?: any
  type:
    | "set cardId"
    | "set products"
    | "reset cart"
    | "set cart preview open"
    | "set cart initialised"
}

function reducer(state: CartState, action: CartActions) {
  const { payload, type } = action

  switch (type) {
    case "set cardId":
      return { ...state, id: payload }
    case "set products":
      return {
        ...state,
        products: payload,
      }
    case "set cart initialised":
      return {
        ...state,
        initialised: true,
      }
    case "reset cart":
      return {
        ...state,
        id: null,
        products: [],
        cartPreviewOpen: false,
      }
    case "set cart preview open":
      return {
        ...state,
        cartPreviewOpen: payload,
      }
    default:
      return state
  }
}

type CartState = {
  id: string | null
  initialised: boolean
  products: YocoCom.ConsolidatedCartItem[] | []
  setCartId: (id: string) => void
  setProducts: (product: YocoCom.ConsolidatedCartItem[]) => void
  resetCart: () => void
  cartPreviewOpen: boolean
  setCartPreviewOpen: (open: boolean) => void
  cartDisabled: boolean
}

const emptyFn = (): void | Promise<void> => {
  console.warn(`StoreContext not ready!`)
}

export const INITIAL_VALUES: CartState = {
  id: null,
  initialised: false,
  products: [],
  setCartId: emptyFn,
  setProducts: emptyFn,
  resetCart: emptyFn,
  cartPreviewOpen: false,
  setCartPreviewOpen: emptyFn,
  cartDisabled: false,
}

export const CartReducer = () => {
  const [cart, dispatch] = useReducer(reducer, INITIAL_VALUES)

  const setCartId = useCallback((id: string | null) => {
    dispatch({
      type: "set cardId",
      payload: id,
    })
  }, [])

  const setProducts = useCallback(
    (
      products:
        | (YocoCom.ConsolidatedBundle | YocoCom.ConsolidatedProduct)[]
        | []
    ) => {
      dispatch({
        type: "set products",
        payload: products,
      })
    },
    []
  )

  const setCartInitalised = useCallback(() => {
    dispatch({
      type: "set cart initialised",
    })
  }, [])

  const resetCart = useCallback(() => {
    dispatch({
      type: "reset cart",
      payload: null,
    })
  }, [])

  const setCartPreviewOpen = useCallback((open: boolean) => {
    if (
      typeof navigator !== "undefined" &&
      (navigator.platform === "Win32" || navigator.platform === "Win64")
    ) {
      const isScrollable = document.body.scrollHeight > window.innerHeight

      if (isScrollable) {
        const navbar = document.getElementById("header")
        navbar?.setAttribute(
          "style",
          open
            ? `padding-right: ${WINDOWS_SCROLLBAR_WIDTH}`
            : "padding-right: 0"
        )
        document.body.style.paddingRight = open ? WINDOWS_SCROLLBAR_WIDTH : "0"
      }
    }

    document.body.style.overflowY = open ? "hidden" : "auto"

    dispatch({
      type: "set cart preview open",
      payload: open,
    })
  }, [])

  return {
    cart,
    setCartId,
    setProducts,
    setCartInitalised,
    resetCart,
    setCartPreviewOpen,
  }
}
