// MARK: Used in V2
import React, { PureComponent, createContext } from "react"

import Toast from "./Toast"
import { Notification } from "./types"

type Context = {
  notification: Notification | undefined
  setNotification: (notification: Notification) => void
  dismiss: () => void
}

const emptyFn = (): void | Promise<void> => {
  console.warn(`NotificationContext not ready!`)
}

export const defaultContext: Context = {
  notification: undefined,
  setNotification: emptyFn,
  dismiss: emptyFn,
}

export const NotificationContext = createContext(defaultContext)

type Props = {
  children: any
}

export class NotificationProvider extends PureComponent<Props> {
  state = {
    ...defaultContext,
    setNotification: (notification: Notification): void => {
      this.setState({ notification })
    },
    dismiss: (): void => {
      this.setState({ notification: undefined })
    },
  }

  render() {
    return (
      <NotificationContext.Provider value={this.state}>
        {this.props.children}
        {this.state.notification ? (
          <Toast
            type={this.state.notification.type}
            title={this.state.notification.title}
            message={this.state.notification.message}
            onClose={this.state.dismiss}
          />
        ) : null}
      </NotificationContext.Provider>
    )
  }
}
