// MARK: used in v2.
export const trackSegmentEvent = (
  event: string,
  data: Record<string, any> = {}
): void => {
  if (typeof window === "undefined" || !window.analytics) {
    return console.warn(`Failed to track event: segment is undefined`)
  }

  return window.analytics.track(event, data)
}

export const trackSegmentLinkEvent = (
  element: React.ReactNode,
  event: string,
  data: Record<string, any> = {}
): void => {
  if (typeof window === "undefined" || !window.analytics) {
    return console.warn(`Failed to track event: segment is undefined`)
  }

  return window.analytics.trackLink(element, event, data)
}

export const pushToDataLayer = (data: Record<string, any>): void => {
  if (typeof window === "undefined" || !window.dataLayer) {
    return console.warn(
      `Failed to push to data layer: window.dataLayer is undefined`
    )
  }

  return window.dataLayer.push(data)
}

export const getCookie = (name: string) => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1]
}

export const isMobileDevice = () => {
  const conditions = [
    "Tablet",
    "Mobile",
    "Mobile Safari",
    "IEMobile/xyz",
    "Mobile/xyz",
    "Edge/ ",
  ]

  return conditions.some((condition) => navigator.userAgent.includes(condition))
}
