import React, { useMemo } from "react"

import SubMenuBlock, { SubMenuBlockProps } from "./SubMenuBlock"

const SubMenus = ({ subMenus }: { subMenus: SubMenuBlockProps[] }) => {
  const descriptionSubMenu = useMemo(
    () =>
      subMenus[subMenus.length - 1].type === "description"
        ? subMenus.pop()
        : undefined,
    [subMenus]
  )

  return (
    <>
      <div className="col-start-1 col-end-5 grid grid-cols-4 gap-grid-xs-v2 sm-v2:gap-grid-sm-v2 md-v2:col-start-1 md-v2:col-end-5 md-v2:gap-grid-md-v2 lg-v2:col-start-1 lg-v2:col-end-10 lg-v2:grid-cols-9 lg-v2:gap-grid-lg-v2 xl-v2:gap-grid-xl-v2">
        {subMenus.map((subMenuBlock, subMenuBlockIndex) => (
          <SubMenuBlock
            key={`navigation-submenu-${subMenuBlockIndex}`}
            {...subMenuBlock}
          />
        ))}
      </div>
      <div className="col-start-1 col-end-5 grid md-v2:col-start-5 md-v2:col-end-7 lg-v2:col-start-10 lg-v2:col-end-13">
        {descriptionSubMenu && (
          <SubMenuBlock
            key={`navigation-submenu-description-block`}
            {...descriptionSubMenu}
          />
        )}
      </div>
    </>
  )
}

export default SubMenus
