import { BasicImageProps } from "../types"

const validImageUrlPattern = /^(https?:)?\/\/a.storyblok.com\/f\/*[0-9]+\/*[0-9]*x*[0-9]*\/[A-Za-z0-9]+\/[\S]+\.[a-zA-Z]+/
interface ImageObject {
  image: string
  base64: string
}
function getBasicImageProps(image: string | ImageObject): BasicImageProps {
  let url = ""
  let lqip = null

  if (typeof image === "string") {
    url = image
  }

  if (typeof image === "object") {
    url = image.image
    lqip = image.base64 ? image.base64 : null
  }

  url = validImageUrlPattern.test(url) ? url : ""

  const storyblokRegex = /^(https?:)?\/\/a.storyblok.com\//
  const originalPath = url.replace(storyblokRegex, "")

  const [, , dimensions, , filename] = originalPath.split("/")
  const [width, height] = dimensions
    ?.split("x")
    .map((dimension: string) => parseInt(dimension, 10))
  const [, extension] = filename.split(".")

  const aspectRatio = width / height
  const metadata = { dimensions: { width, height, aspectRatio }, lqip }

  return {
    url,
    extension,
    metadata,
  }
}

export default getBasicImageProps
