export type Dimensions = {
  width: string
  height: string
}

// MARK: Used in V2
export const getImageDimensionsFromUrl = (url: string): Dimensions => {
  //matches the format numberxnumber/ using a positive lookahead assertion
  //lookbehind assertions are not supported in safari at the time of writing
  const retrievedDimension = url.match(/[0-9]+x[0-9]+(?=\/)/)?.[0]
  const dimensions = retrievedDimension?.split("x")

  if (dimensions && dimensions?.length === 2) {
    return {
      width: dimensions[0],
      height: dimensions[1],
    }
  } else {
    console.error(`Failed to getImageDimensionsFromUrl: ${url}`)

    return {
      width: "0",
      height: "0",
    }
  }
}
