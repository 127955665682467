import classNames from "classnames"
import React, { forwardRef } from "react"
import type { PropsWithChildren } from "react"

type Props = {
  id?: string
  className?: string
} & React.DOMAttributes<HTMLDivElement>

const Section = forwardRef<HTMLElement, PropsWithChildren<Props>>(
  ({ children, id, className, ...props }, ref) => {
    return (
      <section
        ref={ref}
        id={id ? id : undefined}
        className={classNames(
          "px-gutter-xs-v2 sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2 lg-v2:px-gutter-lg-v2 xl-v2:px-gutter-xl-v2",
          className
        )}
        {...props}
      >
        {children}
      </section>
    )
  }
)

Section.displayName = "Section"

export default Section
