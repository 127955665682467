/**
 * Note this is the v1 theme used for styled components,
 * please use theme from /src/utils/V2/theme when needing
 * to access tailwind theme attributes with JavaScript
 */
let theme = {
  screens: {
    sm: "744px",
    md: "1024px",
    lg: "1280px",
    xl: "1440px",
    "2xl": "1920px",
  },
  spacing: {
    "gutter-xs": "25px",
    "gutter-sm": "40px",
    "gutter-md": "78px",
    "gutter-lg": "98px",
    "gutter-xl": "100px",
    "gutter-2xl": "208px",
    "no-gutter": "0px !important",
  },
  colors: {
    naartjie: "#F75E3F",
    charcoal: "#3F3F3F",
    "orange-peel": "#FF9E00",
    trinidad: "#F95D36",
    cerulean: "#00A9E0",
    "aqua-deep": "#014737",
    juniper: "#709990",
    flamingo: "#F95C2C",
    "mine-shaft-dark": "#222222",
    "dove-grey": "#676767",
    white: "#FFFFFF",
    "powder-ash": "#BFCEC9",
    mystic: "#E1EAE8",
    "wild-sand": "#F4F4F4",
    "mine-shaft-light": "#313131",
    gray: "#888888",
    black: "#000000",
    fire: "#B53200",
    "mystic-light": "#E0EAE9",
    "mystic-dark": "#CAD8D3",
    "deep-teal": "#003C2E",
    "fair-pink": "#FFEDE8",
    "sandy-beach": "#FFEAC5",
    alto: "#D8D8D8",
    "mine-shaft": "#262626",
    iron: "#D9E0E0",
    "deep-sea-green": "#095947",
    "dusty-gray": "#979797",
    "alizarin-crimson": "#DC2626",
    transparent: "#00 FFFFFF",
    "tuft-bush": "#FFD4C6",
    "white-smoke": "#F4F4F4",
    sazerac: "#FFF3E2",
    pippin: "#FFE9E2",
    cascade: "#8FAEA7",
    "humming-bird": "#DFF5FB",
    opal: "#A7C1BB",
    "glassdoor-green": "#0CAA41",
    "blood-orange": "#ec380a",
    "toast-success": "#018668",
    "toast-warning": "#FF9E00",
    "toast-danger": "#f44336",
    "toast-info": "#00a9e0",
    "iron-gray": "#ccd5df",
  },
}

export default theme
