import { defaultOptions, srcSetBreakpoints } from "./defaults"
import { GatsbyImage, Options } from "./types"
import buildUrl from "./utils/buildImageUrl"
import getBasicImageProps from "./utils/getBasicImageProps"
import { getWidths } from "./utils/helpers"

function getGatsbyImage(image: string, args: Options): GatsbyImage {
  const imageProps = getBasicImageProps(image)

  const {
    metadata: { dimensions },
    url,
  } = imageProps

  const options = {
    width: args.width || dimensions.width,
    height: args.height,
    layout: args.layout,
    quality: args.quality || defaultOptions.quality,
    aspectRatio: args.aspectRatio,
    format: defaultOptions.format,
    toFormat: defaultOptions.toFormat,
    smartCrop: defaultOptions.smartCrop,
  }

  let desiredAspectRatio = dimensions.aspectRatio

  // If we're cropping, calculate the specified aspect ratio
  if (options.height) {
    desiredAspectRatio = options.width / options.height
  }

  const height =
    options.height || Math.round(options.width / dimensions.aspectRatio)

  const widths = getWidths(options.layout, options.width)

  const srcSets = widths.map((currentWidth, index) => {
    const currentHeight = Math.round(currentWidth / desiredAspectRatio)

    const size = {
      width: currentWidth,
      height: currentHeight,
    }

    const baseUrl = buildUrl(url, {
      ...options,
      ...size,
    })

    return `${baseUrl} ${srcSetBreakpoints[index]}w`
  })

  const imgSize = { width: options.width, height: height }

  const src = buildUrl(url, {
    ...options,
    ...imgSize,
    ...{ format: options.toFormat ? options.toFormat : "webp" },
  })

  return {
    width: Math.round(options.width),
    height: Math.round(height),
    aspectRatio: options.aspectRatio ?? desiredAspectRatio,
    layout: options.layout,
    images: {
      fallback: { src, srcSet: srcSets.join(",\n") || "" },
    },
  }
}

export default getGatsbyImage
