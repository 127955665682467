import "swiper/swiper-bundle.min.css"
import wrapPageElement from "./src/wrapPageElement"
import wrapRootElement from "./src/wrapRootElement"

const onRouteUpdate = ({ location, prevLocation }) => {
  window.analytics &&
    window.analytics.page({ path: location.pathname, url: location.href })
  window.prevUrl = prevLocation ? prevLocation.pathname : undefined
}

export { wrapRootElement, wrapPageElement, onRouteUpdate }
