import { useStaticQuery, graphql } from "gatsby"
import { useCallback } from "react"

import { consolidateProduct } from "../../../utils/shop"

import { useGetProductBySku } from "@hooks/products"
import { useGetStoryblokBundle } from "@hooks/V2/storyblok/useBundle"

interface storyBlokProduct {
  uuid: string
  content: string
  full_slug: string
}

export const useGetStoryblokProduct = (): ((
  id: string,
  useSlug?: boolean
) => Storyblok.ProductConfig | null) => {
  const data = useStaticQuery(graphql`
    query StoryblokProducts {
      allStoryblokEntry(
        filter: { full_slug: { regex: "/settings/products/" } }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `)

  const getStoryblokProduct = useCallback(
    (id: string, useSlug = false) => {
      const product = data.allStoryblokEntry.edges.find(
        (product: { node: storyBlokProduct }) => {
          if (useSlug) {
            return product.node.full_slug === id
          } else {
            return product.node.uuid === id
          }
        }
      )

      if (product) {
        return JSON.parse(product.node.content)
      }

      return null
    },
    [data]
  )

  return getStoryblokProduct
}

export const useGetProductBySlug = (): ((
  slug: string
) =>
  | Storyblok.ProductConfig
  | YocoCom.ConsolidatedCartItemBase
  | Storyblok.BundleConfig
  | null) => {
  const getStoryBlokProduct = useGetStoryblokProduct()
  const getStoryBlokBundle = useGetStoryblokBundle()
  const getSdkProduct = useGetProductBySku()

  const getProductBySlug = useCallback(
    (slug: string) => {
      if (slug.startsWith("settings/products/")) {
        const storyblokProduct = getStoryBlokProduct(slug, true)

        if (storyblokProduct) {
          const sdkProduct = getSdkProduct(storyblokProduct.sku)

          if (sdkProduct) {
            return consolidateProduct(
              sdkProduct.id.toString(),
              1,
              sdkProduct,
              storyblokProduct
            )
          }

          return storyblokProduct
        }
      }

      if (slug.startsWith("settings/bundles/")) {
        const storyblokBundle = getStoryBlokBundle(slug, true)

        if (storyblokBundle) {
          return storyblokBundle
        }
      }

      return null
    },
    [getSdkProduct, getStoryBlokProduct, getStoryBlokBundle]
  )

  return getProductBySlug
}
