export enum Gutter {
  ExtraSmall = "xs",
  Small = "sm",
  Large = "lg",
}

export enum ButtonSize {
  small = "small",
  normal = "normal",
  large = "large",
}

export enum ArrowSpace {
  small = "small",
  normal = "normal",
  large = "large",
}

export const WINDOWS_SCROLLBAR_WIDTH = "17px"

export const storyblokVerticalMarginClasses =
  "my-gutter-xs sm:my-gutter-sm md:my-gutter-md lg:my-gutter-lg"

export const storyblokHorizontalLeftGutterClasses =
  "pl-gutter-xs sm:pl-gutter-sm md:pl-gutter-md lg:pl-gutter-lg xl:pl-gutter-xl 2xl:pl-gutter-2xl"

export const storyblokHorizontalRightGutterClasses =
  "pr-gutter-xs sm:pr-gutter-sm md:pr-gutter-md lg:pr-gutter-lg xl:pr-gutter-xl 2xl:pr-gutter-2xl"

export const storyblokHorizontalGutterClasses = `${storyblokHorizontalLeftGutterClasses} ${storyblokHorizontalRightGutterClasses}`

export const storyblokNegativeHorizontalGutterClasses =
  "-mx-gutter-xs sm:-mx-gutter-sm md:-mx-gutter-md lg:-mx-gutter-lg xl:-mx-gutter-xl 2xl:-mx-gutter-2xl"
