import React from "react"

import { Color } from "@constants/V2/color"
import { getStrokeColorClass } from "@utils/V2/color"

type Props = {
  strokeColor?: Color
  strokeWidth?: string
  hideBorder?: boolean
  width?: string
  height?: string
  onClick?: () => void
}

const CloseIcon = ({
  strokeColor = Color.White,
  strokeWidth = "2.7",
  width = "30px",
  height = "30px",
  onClick,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={getStrokeColorClass(strokeColor)}
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M8.01172 21.9877L21.9887 8.01172" strokeWidth={strokeWidth} />
      <path d="M21.9882 21.9877L8.01123 8.01172" strokeWidth={strokeWidth} />
    </svg>
  )
}

export default CloseIcon
