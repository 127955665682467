import {
  sizeMultipliersConstrained,
  sizeMultipliersFullwidth,
} from "../defaults"

export function applyFilters(filters: string[]): string {
  return filters.reduce((acc: string, currentFilter: string) => {
    return `${acc}:${currentFilter}`
  }, "/filters")
}

export function isWebP(url: string): boolean {
  const isConverted = url.includes("filters:format(webp)")
  const isOriginal = /[a-f0-9]+-\d+x\d+\.webp/.test(url)
  return isConverted || isOriginal
}

export function getWidths(layout: string, dimensionWidth: number): number[] {
  let widths = [0]
  switch (layout) {
    case "fixed": {
      widths = [dimensionWidth]
      break
    }
    case "fullWidth": {
      widths = sizeMultipliersFullwidth.map((scale) =>
        Math.round(dimensionWidth * scale)
      )
      break
    }
    case "constrained": {
      widths = sizeMultipliersConstrained.map((scale) =>
        Math.round(dimensionWidth * scale)
      )
      break
    }
    default:
      widths = [0]
  }
  return widths
}
